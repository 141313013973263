<template>
  <TransitionRoot appear :show="visible" as="template">
    <Dialog
      as="div"
      class="relative z-30 w-full"
      @close="setearModalCarruselesProductos(false)"
    >
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full justify-center text-center md:items-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="min-h-full w-full transform bg-white text-left align-middle shadow-xl transition-all lg: md:rounded-md max-w-3xl lg:max-w-4xl"
            >
              <button
                class="absolute right-0 top-0 p-2"
                @click="setearModalCarruselesProductos(false)"
              >
                <svg
                  class="h-6 w-6 text-gray-400 md:hover:text-gray-500"
                  fill="none"
                  stroke="currentColor"
                  viewBox="0 0 24 24"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
              <!-- CONTENIDO -->
              <div class="p-6">
                <h1 class="text-xl font-semibold text-center mb-2">
                  Pensados para ti 😍
                </h1>

                <h3
                  class="border-b text-xl leading-7 font-semibold border-gray-400 pb-1"
                >
                  {{ tituloCarrusel }}
                </h3>
              </div>
              <div id="carrusel-productos-modal" class="md:px-6 lg:mx-2.5">
                <CarruselProductos
                  :productos="productos"
                  parent="carrusel-productos-modal"
                />
              </div>
              <div class="p-6">
                <div class="flex justify-center lg:px-24">
                  <Button type="secondary" class="w-full" @click="irACheckout">
                    Ir al checkout
                  </Button>
                </div>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script setup lang="ts">
  import { ref, onMounted, watch } from "@vue/runtime-core";
  import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
  } from "@headlessui/vue";
  import { getProductosCompradosJuntosCarro } from "@lib/plugins/algolia";
  import {
    itemsCarro,
    setearLoadingBotonIrAPagar,
    loadingIrAPagar,
    carroVisible,
    closeCart,
  } from "@stores/carro";
  import { useStore } from "@nanostores/vue";
  import type { Producto } from "@lib/interfaces";
  import { actualizarPrecioOfertaStockDisponibleProductos } from "@stores/app";
  import CarruselProductos from "@components/carruseles/CarruselProductos.vue";
  import {
    getProductosOfertaCheckout,
    getProductosRecomendadosFrest,
  } from "@api/client/productos";
  import Button from "@components/ui/Button.vue";
  import {
    modalCarruselesProductos,
    setearModalCarruselesProductos,
  } from "@stores/checkout";

  const $itemsCarro = useStore(itemsCarro);
  const visible = ref(false);
  const $modalCarruselesProductos = useStore(modalCarruselesProductos);
  const productos = ref<Producto[]>([]);
  const tituloCarrusel = ref("");
  const minimoProductos = 5;
  const $loadingIrAPagar = useStore(loadingIrAPagar);
  const $carroVisible = useStore(carroVisible);
  const getProductos = async () => {
    const idProductosCarro = $itemsCarro.value.map((item) => item.producto_id);
    let responseProductos = await getProductosAlgolia();
    if (responseProductos.length >= minimoProductos) {
      productos.value = responseProductos;
      tituloCarrusel.value = "Frecuentemente comprados juntos";
      return;
    }
    responseProductos = await getProductosRecomendadosFrest(idProductosCarro);
    if (responseProductos.length >= minimoProductos) {
      productos.value = responseProductos;
      tituloCarrusel.value = "Recomendados";
      return;
    }
    responseProductos = await getProductosOfertaCheckout(idProductosCarro);
    if (responseProductos.length >= minimoProductos) {
      productos.value = responseProductos;
      tituloCarrusel.value = "Ofertas";
      return;
    }
  };

  const getProductosAlgolia = async () => {
    let responseAlgolia = await getProductosCompradosJuntosCarro(
      $itemsCarro.value,
    );
    await actualizarPrecioOfertaStockDisponibleProductos(responseAlgolia);
    return responseAlgolia.filter((producto) => {
      if (producto.stock_disponible < producto.intervalo) return false;
      return producto.activo_bodega;
    });
  };

  const irACheckout = () => {
    setearModalCarruselesProductos(false);
    window.location.href = "/checkout";
  };

  const cerrarCarroEnCasoDeEstarAbierto = () => {
    if ($carroVisible.value) {
      closeCart();
    }
  };

  watch($modalCarruselesProductos, async (value) => {
    if (value) {
      await getProductos();
      if ($loadingIrAPagar.value) {
        setearLoadingBotonIrAPagar(false);
      }
      visible.value = true;
      cerrarCarroEnCasoDeEstarAbierto();
      return;
    }
    visible.value = false;
  });

  onMounted(async () => {
    if ($modalCarruselesProductos.value) {
      await getProductos();
      if ($loadingIrAPagar.value) {
        setearLoadingBotonIrAPagar(false);
      }
      visible.value = true;
      cerrarCarroEnCasoDeEstarAbierto();
    }
  });
</script>
